@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button:focus {
  outline:none;
  box-shadow: none;
}
html, .App{
  background: #4b6cb7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.btn-primary.unborder{
  background-color: transparent !important;
  border-color:white;
  font-weight: bold;
}
.btn-primary.gradient{
  background: #4b6cb7;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #182848, #2b437d);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #182848, #2b437d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  border:solid 0px;
  font-weight: bold;
}

.list-group-item.separated{
  margin-top: 1rem;
  border: solid 1px rgba(0,0,0,0.4) !important;
  border-radius: 4px;
  border-top-width: 1px !important;
}

.App-Footer{
  display: flex;
  flex-direction: column;
}

.navbar{
  height: 78px;
  width: 100%;  
  max-width: 1180px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.cm-scroller{
  border-radius: 4px;
  border: solid 1px #ced4da;
}
.destiny-box{
  display: flex;
}
.btn-primary:hover{
  border-color: black !important;
  color: black !important;
}
.accordion-button{
  background-color: transparent !important;
  border:solid 0px white !important;
  color: white !important;
  font-weight: bold !important;  
  box-shadow: 0px !important;   
}

.accordion-item{
  margin: 1rem;
}
.logo{
  font-size: 20px;
  color:white;
  font-weight: bold;
  cursor: pointer;
}
.dropdown-item{
  font-size:12px;
}
.App-Body{
  width: 100%;
  max-width: 700px;
  height: 100%;
  display: flex;  
  flex-direction: column;
  margin: 1rem auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes ldio-dwpebok3bgh {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-dwpebok3bgh div {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 5px solid #1d0e0b;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-dwpebok3bgh div {
  animation: ldio-dwpebok3bgh 1s linear infinite;
  top: 100px;
  left: 100px
}
.loadingio-spinner-rolling-o18mgj7sztd {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-dwpebok3bgh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-dwpebok3bgh div { box-sizing: content-box; }
/* generated by https://loading.io/ */
